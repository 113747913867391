import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_DRAGON_TOKEN_ID,
  SET_DRAGON_TOKEN_ID_LOADING,
  SET_TRANSACTION,
} from 'redux/reducers/payment/actionTypes';
import {
  SET_OPEN_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
} from 'redux/reducers/global/actionTypes';
import { dragonTokenId } from 'services/payment/paymentApi';

function* requestPaymenDragon(action) {
  yield put({ type: SET_DRAGON_TOKEN_ID_LOADING });
  console.log('action.bode dragontoken', action.body);
  let result: any = yield call(dragonTokenId, action.body);
  console.log('requestPaymenDragon result', result);
  if (result && result.response && result.error) {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: `Sorry, ${result.response.status} ${result.response.statusText}`,
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    return;
  }
  yield put({ type: SET_TRANSACTION, result });
  yield put({ type: SET_DRAGON_TOKEN_ID_LOADING });
}
function* getDragonTokenId() {
  yield takeLatest(GET_DRAGON_TOKEN_ID, requestPaymenDragon);
}

export default getDragonTokenId;
