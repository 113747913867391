import React from 'react';
import { History } from 'history';
import styled, { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import Routes from 'routers';
import theme from 'config/theme';
import { Header, ErrorMessageBox } from 'components';
import Footer from 'components/Footer';
import { connect } from 'react-redux';
import { reducerType } from 'redux/reducers';
//require('dotenv').config({ path: process.env.NODE_ENV === 'development' ? '.env.development' : '.env.production' });

const AppContainer = styled.div`
  background-color: ${prop => prop.theme.bg};
  height: 100%;
`;
console.log('process.env.ENV: ', process.env);
const mapStateToProps = (state: reducerType) => {
  return {
    isOpenTopAlert: state.global.isOpenTopAlert,
  };
};
type AppProps = {
  history: History;
  isOpenTopAlert: boolean;
};

const App = ({ history, isOpenTopAlert }: AppProps) => {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Header />
        {isOpenTopAlert && <ErrorMessageBox />}
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
        <Footer />
      </AppContainer>
    </ThemeProvider>
  );
};

export default connect(mapStateToProps)(App);
