import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: theme.bgButton,
      '&:hover': {
        backgroundColor: theme.bgButton,
        boxShadow: 'unset',
      },
      height: 46,
      borderRadius: 4,
      color: 'white !important',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      cursor: 'pointer',
      boxShadow: 'unset!important',
    },
    btnDisabled: {
      backgroundColor: '#A4A4A4 !important',
    },
  })
);

export default useStyles;
