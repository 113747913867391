import React, { useEffect } from 'react';
import StyledErrorBox from './StyledErrorBox';
import { ErrorMessage } from 'components';
import { connect } from 'react-redux';
import { reducerType } from 'redux/reducers';
import { topAlertContent } from 'models/global';
import { useDispatch } from 'react-redux';
import { SET_CLOSE_TOP_ALERT } from 'redux/reducers/global/actionTypes';
import { Grid } from '@material-ui/core';
import styles from './styles';

const mapStateToProps = (state: reducerType) => {
  return {
    topAlertContent: state.global.topAlertContent,
  };
};
interface errorMessageBox {
  topAlertContent: topAlertContent;
}

const ErrorMessageBox = React.memo((props: errorMessageBox) => {
  const dispatch = useDispatch();
  const classes = styles();
  const closeTopAlert = () => {
    dispatch({
      type: SET_CLOSE_TOP_ALERT,
    });
  };
  const { topAlertContent } = props;

  return (
    <div className={classes.root}>
      <StyledErrorBox>
        <Grid container>
          <Grid item style={{ textAlign: 'center', paddingLeft: '10px' }}>
            <i className={topAlertContent.iconType} />
          </Grid>
          <Grid item xs>
            {topAlertContent.message && topAlertContent.message}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            <i className={topAlertContent.iconClose} onClick={closeTopAlert} />
          </Grid>
        </Grid>
      </StyledErrorBox>
    </div>
  );
});

export default connect(mapStateToProps)(ErrorMessageBox);
