import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { insurancetypeApi } from 'services/policy/policyApi';
import {
  GET_INSURANCE_TYPE,
  SET_INSURANCE_TYPE,
} from 'redux/reducers/policy/actionTypes';
import {
  SET_OPEN_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
  SET_CLOSE_TOP_ALERT,
  SET_IS_CALL_INSURANCE,
  SET_IS_NOT_CALL_INSURANCE,
} from 'redux/reducers/global/actionTypes';

function* requestInsuranceType(action) {
  yield put({ type: SET_IS_NOT_CALL_INSURANCE });
  const result = yield call(insurancetypeApi, action.params);
  if (result && result.response && result.error) {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: `Sorry, ${result.response.status} ${result.response.statusText}`,
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    return;
  }
  if (!!result) {
    yield put({ type: SET_IS_CALL_INSURANCE });
    //yield put({ type: SET_CLOSE_TOP_ALERT });
    yield put({ type: SET_INSURANCE_TYPE, result });
  }
}
function* getInsuranceType() {
  yield takeLatest(GET_INSURANCE_TYPE, requestInsuranceType);
}

export default getInsuranceType;
