import { Tooltip, withStyles } from '@material-ui/core';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    marginTop: 15,
    backgroundColor: '#FFF9E5',
    color: '#575756',
    fontSize: 12,
    borderRadius: 8,
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  arrow: {
    color: '#FFF9E5',
  },
}))(Tooltip);

export default LightTooltip;
