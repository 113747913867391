export interface paymentDue {
  BALANCEDUE: number;
  LAPSEDATE: string;
  MESSAGE: string;
  PREMIUMSUSPENSE: number;
  MODALPREMIUM: number;
  TOTALBALANCE: number;
}

export interface initialPaymentDue {
  POLICYNUMBER: string;
  REFNUM: string;
  CONTRACTCUR: string;
  INSTALLMENTPREM: number;
  PREMSUSPENSE: number;
  TOLCONTRACTAMT: number;
  TOLCOLLECTIONAMT: number;
}

export interface dragonBill {
  refNo: string | null;
  settleDate: string | null;
  amount: string | null;
  initialAmount: string | null;
  currency: string | null;
  policyNum: string | null;
  owner: string | null;
  productName: string | null;
  payType: string | null;
  clientCode: string | null;
}

export const dragonBillInitial: dragonBill = {
  refNo: null,
  settleDate: '',
  amount: '',
  initialAmount: '',
  currency: '',
  policyNum: '',
  owner: '',
  productName: '',
  payType: '',
  clientCode: '',
};

export interface paymenthistoryList {
  PYMTBANKDESC: string;
  ACCTAMT: number;
  LONGDESC: string;
  POLICYNUMBER: string;
  AUD_Time: Date;
  PYMTBANKCODE: string;
  EFFDATE: Date;
  ORIGAMT: number;
  RECEIPTNUMBER: string;
  TRANSDESC: string;
  TRANDATE: Date;
}
export interface paymentAction {
  type: string;
  payload: string;
}

export interface paymentReceiption {
  PAYORNUMBER: string;
  RECEIPTDATE: string;
  TOTALAMOUNT: string;
  CURRENCY: string;
  PAYMENTTYPE: string;
  ACCOUNTINGAMOUNT: string;
  POLICYNUMBER: string;
  RECEIPTAMOUNT: string;
  APPLICATIONNUMBER: string;
  PAYMENTPURPOSE: string;
  BANKCODE: string;
  MERCHANTID: string;
  REFERENCENUM: string;
}

export const paymentReceiptionInitial: paymentReceiption = {
  PAYORNUMBER: '',
  RECEIPTDATE: '',
  TOTALAMOUNT: '',
  CURRENCY: '',
  PAYMENTTYPE: '',
  ACCOUNTINGAMOUNT: '',
  POLICYNUMBER: '',
  RECEIPTAMOUNT: '',
  APPLICATIONNUMBER: '',
  PAYMENTPURPOSE: '',
  BANKCODE: '',
  MERCHANTID: '',
  REFERENCENUM: '',
};
