export interface insuranceType {
  UPDATEDON: string;
  INSURANCETYPEID: number;
  CREATEDON: string;
  ID: number;
  PLANCODE: string;
  UPDATEDBY: string;
  TYPE: string;
  ISACTIVE: number;
  CREATEDBY: string;
}

export const insuranceTypeInitial: insuranceType = {
  UPDATEDON: '',
  INSURANCETYPEID: 0,
  CREATEDON: '',
  ID: 0,
  PLANCODE: '',
  UPDATEDBY: '',
  TYPE: '',
  ISACTIVE: 0,
  CREATEDBY: '',
};

export interface policyPaymentInfo {
  TOTALINSTALLMENTPREMIUM: number;
  INSTALLMENTPREMIUM: number;
  BILLTODATE: string;
  PAYMENTMODE: string;
  FIRSTNAME: string;
  BIRTHDATE: string;
  POLICYDATE: string;
  EMAIL: string;
  PAYMENTMETHOD: string;
  LIFESUMINSURED: number;
  EXTENSIONNAME: string;
  MIDDLENAME: string;
  PREMIUMSTATUSCODE: string;
  LASTNAME: string;
  BILLFREQUENCY: string;
  PLANCODE: string;
  CLIENTCODE: string;
  STATUSCODE: string;
  BILLINGCHANNEL: string;
  SINGLEPREMIUM: string;
  CURRENCYCODE: string;
  PAIDTODATE: string;
  POLICYNUMBER: string;
  TITLE: string;
  PLANCODEDESC: string;
}

export interface maintenanceState {
  ID: number;
  isVisible: number;
}

export interface initialpolicyPaymentInfo {
  TOTALINSTALLMENTPREMIUM: 0;
  INSTALLMENTPREMIUM: 0;
  BILLTODATE: '';
  PAYMENTMODE: '';
  FIRSTNAME: '';
  BIRTHDATE: '';
  POLICYDATE: '';
  EMAIL: '';
  PAYMENTMETHOD: '';
  LIFESUMINSURED: 0;
  EXTENSIONNAME: '';
  MIDDLENAME: '';
  PREMIUMSTATUSCODE: '';
  LASTNAME: '';
  BILLFREQUENCY: '';
  PLANCODE: '';
  CLIENTCODE: '';
  STATUSCODE: '';
  BILLINGCHANNEL: '';
  SINGLEPREMIUM: '';
  CURRENCYCODE: '';
  PAIDTODATE: '';
  POLICYNUMBER: '';
  TITLE: '';
  PLANCODEDESC: '';
}

export interface policyType {
  'insurance-type': insuranceType;
  'policy-list': {
    POLICYNUMBER: string;
    PLANCATEGORY: string;
    ROLECODE: string;
    CLIENTCODE: string;
  };
  'policy-detail': policyDetail;
  'policy-rpqscore': [
    {
      RPQSCORE: number;
      POLICYNUMBER: string;
    }
  ];
  'policydocument-list': [
    {
      DOCUMENTID: string;
      TRANSACTION_DATE: string;
      POLICYNUMBER: string;
      DOCUMENTCD: string;
      DOCTYPE: string;
      DOCUMENT_DESC: string;
    }
  ];
  'policy-roles': [
    {
      RELATION: string;
      CLAIMPOLICYHISTORY: string;
      COMPANYCODE: string;
      EFFECTIVEFROMDATE: string;
      EFFECTIVETODATE: null;
      DECISIONCD: null;
      FINANCIALSAR: null;
      ROLECODE: string;
      BENIFIRYTYPE: string;
      AGEATISSUE: null;
      REVOCABLEFL: string;
      ADVERSEPOLICYHISTORY: string;
      ASSIGNMENTTYPECD: null;
      IRREVOCABLEFL: null;
      POLICYNUMBER: string;
      RELATIONSHIPCODE: string;
      CLIENTCODE: string;
      ALLOCATIONPERCENTAGE: number;
      CLIENTREFSEQ: string;
    }
  ];
  BO_available: boolean;
}

export interface policyDetail {
  POLICYINFO: [policyInfoType];
  POLICYFUND: [policyFundType];
  AGENTINFO: [agentInfoType];
  PRODUCTINFO: [productInfoType];
  DISPATCHADDRESS: [dispatchAddressType];
  CLIENTINFO: [clientInfoType];
  BANKINFO: [bankInfoType];
}
export interface policyInfoType {
  TOTALINSTALLMENTPREMIUM: number;
  INSTALLMENTPREMIUM: number;
  ISSUEDATE: string;
  BILLTODATE: string;
  PAYMENTMODE: string;
  STATUSCHANGEDATE: string;
  FIRSTNAME: string;
  NONFORFEITUREOPTIONDESC: number;
  POLICYDATE: string;
  OWNER_BIRTHDATE: string;
  PAYMENTMETHOD: string;
  LIFESUMINSURED: number;
  MIDDLENAME: string;
  PREMIUMSTATUSCODE: string;
  BILLFREQUENCY: string;
  PLANCODE: string;
  FIRSTISSUEDATE: string;
  CLIENTCODE: string;
  DISPATCHCLIENTCODE: string;
  CURRENCY: string;
  SUBMITDATE: string;
  SURNAME: string;
  STATUSCODE: string;
  AGEATCOMMENCEMENT: number;
  AGENTCODE: string;
  BILLINGCHANNEL: string;
  NONFORFEITUREOPTION: string;
  SINGLEPREMIUM: number;
  CURRENCYCODE: string;
  PAIDTODATE: string;
  POLICYNUMBER: string;
  RISKCESSATIONDATE: string;
  PLANCATEGORY: string;
  PLANCATEGORYDESC: string;
  PLANCODEDESC: string;
  STATUSCODEDESC: string;
}

interface policyFundType {
  FUNDPRICE: number;
  FUNDCURRENCY: string;
  ALLOCATIONPERCNT: number;
  POLICYNUMBER: string;
  FUNDVALUE: number;
  FUNDCODE: string;
  TOTALUNITS: number;
  FUNDDESC: string;
  FUNDLONGDESC: string;
  FUNDTYPE: string;
  EFFECTIVEDATE: Date;
}

interface agentInfoType {
  SERVICINGBRANCHNAME: number;
  SERVICINGBRANCHID: string;
  CITIZENSHIPCD: string;
  CONTACTNUM: string;
  EMAILADDRESS: string;
  SERVICINGBRANCHCONTACTNUM: number;
  AGENTSTATUSCD: string;
  DESIGNATIONCD: string;
  CONTACTTYPECD: string;
  CHANNELCD: string;
  AGENTID: string;
  AGENTNAME: string;
}

interface productInfoType {
  PREMCESSATIONTERM: number;
  INSTALLMENTPREMIUM: number;
  AGEATCOMMENCEMENT: number;
  PLANLONGDESC: string;
  PAYMENTMODE: string;
  SINGLEPREMIUM: number;
  PLANSUBTYPE: string;
  PAYMENTMETHOD: string;
  COVERAGENUMBER: string;
  LIFESUMINSURED: number;
  STATUS: string;
  POLICYNUMBER: string;
  PLANTYPE: string;
  RISKCESSATIONDATE: Date;
  LIFENUMBER: string;
  RIDERNUMBER: string;
  PLANSHORTDESC: string;
  PLANCODE: string;
  PLANSUBDESC: string;
  PLANCODEDESC: string;
}

interface dispatchAddressType {
  ADDRESS5: string;
  ADDRESS4: string;
  ADDRESSTYPE: string;
  ZIPCODE: string;
  ALTCLIENTCODE: string;
  COUNTRYCODE: string;
  ALTCLIENTIND: string;
  SMSFLAG: string;
  ADDRESS1: string;
  CLIENTCODE: string;
  ADDRESS3: string;
  MAILINGSTATUS: string;
  ADDRESS2: string;
}

interface clientInfoType {
  NATIONALITY: string;
  IDENTITYTYPE: string;
  ADDRESSTYPE: string;
  MAIDENLASTNAME: string;
  IDENTITYNO: string;
  USTINNO: string;
  FIRSTNAME: string;
  BIRTHDATE: Date;
  EMAIL: string;
  STATUS: string;
  EXTENSIONNAME: string;
  MIDDLENAME: string;
  LASTNAME: string;
  MARITALSTATUS: string;
  ADDRESSSMSFLAG: string;
  ALTCLIENTIND: string;
  GENDER: string;
  SMSFLAG: string;
  ADDRESS1: string;
  GSISnumber: string;
  CLIENTCODE: string;
  ADDRESS3: string;
  ADDRESS2: string;
  ADDRESS5: string;
  ADDRESS4: string;
  ZIPCODE: string;
  MIB: string;
  CLIENTTYPE: string;
  MOBILENO: string;
  BIRTHPLACE: string;
  FATCA: string;
  MAIDENFIRSTNAME: string;
  FATCAEFFDATE: number;
  ALTCLIENTCODE: string;
  COUNTRYCODE: string;
  OCCUPATIONCODE: string;
  TITLE: string;
  SECURITYNO: string;
  PHONE2: string;
  PHONE1: string;
  FAX: string;
  SSSNUMBER: string;
  TINNUMBER: string;
  MAILINGSTATUS: string;
  AMLA: string;
}

interface bankInfoType {
  FROMYEAR: number;
  EXPIRYYEAR: number;
  MANDATEREFNO: string;
  ACCOUNTTYPE: string;
  ACCOUNTNUMBER: string;
  BANKNAME: string;
  ACCOUNTNAME: string;
  EXPIRYMONTH: number;
  POLICYNUMBER: string;
  BANKKEY: string;
  PAYERNUMBER: string;
  CARDTYPE: number;
  FROMMONTH: number;
}

export interface policyAction {
  type: string;
  params: policyType;
}

export interface policyReducer {
  data: policyType[];
}
export const initialPolicyInfo = {
  TOTALINSTALLMENTPREMIUM: 0,
  INSTALLMENTPREMIUM: 0,
  ISSUEDATE: '',
  BILLTODATE: '',
  PAYMENTMODE: '',
  STATUSCHANGEDATE: '',
  FIRSTNAME: '',
  NONFORFEITUREOPTIONDESC: 0,
  POLICYDATE: '',
  OWNER_BIRTHDATE: '',
  PAYMENTMETHOD: '',
  LIFESUMINSURED: 0,
  MIDDLENAME: '',
  PREMIUMSTATUSCODE: '',
  BILLFREQUENCY: '',
  PLANCODE: '',
  FIRSTISSUEDATE: '',
  CLIENTCODE: '',
  DISPATCHCLIENTCODE: '',
  CURRENCY: '',
  SUBMITDATE: '',
  SURNAME: '',
  STATUSCODE: '',
  AGEATCOMMENCEMENT: 0,
  AGENTCODE: '',
  BILLINGCHANNEL: '',
  NONFORFEITUREOPTION: '',
  SINGLEPREMIUM: 0,
  CURRENCYCODE: '',
  PAIDTODATE: '',
  POLICYNUMBER: '',
  RISKCESSATIONDATE: '',
  PLANCATEGORY: '',
  PLANCATEGORYDESC: '',
  PLANCODEDESC: '',
  STATUSCODEDESC: '',
};

const initialPolicyFund = {
  FUNDPRICE: 0,
  FUNDCURRENCY: '',
  ALLOCATIONPERCNT: 0,
  POLICYNUMBER: '',
  FUNDVALUE: 0,
  FUNDCODE: '',
  TOTALUNITS: 0,
  FUNDDESC: '',
  FUNDLONGDESC: '',
  FUNDTYPE: '',
  EFFECTIVEDATE: '',
};

const initialAgentInfo = {
  SERVICINGBRANCHNAME: 0,
  SERVICINGBRANCHID: '',
  CITIZENSHIPCD: '',
  CONTACTNUM: '',
  EMAILADDRESS: '',
  SERVICINGBRANCHCONTACTNUM: 0,
  AGENTSTATUSCD: '',
  DESIGNATIONCD: '',
  CONTACTTYPECD: '',
  CHANNELCD: '',
  AGENTID: '',
  AGENTNAME: '',
};

const initialProductInfo = {
  PREMCESSATIONTERM: 0,
  INSTALLMENTPREMIUM: 0,
  AGEATCOMMENCEMENT: 0,
  PLANLONGDESC: '',
  PAYMENTMODE: '',
  SINGLEPREMIUM: 0,
  PLANSUBTYPE: '',
  PAYMENTMETHOD: '',
  COVERAGENUMBER: '',
  LIFESUMINSURED: 0,
  STATUS: '',
  POLICYNUMBER: '',
  PLANTYPE: '',
  RISKCESSATIONDATE: '',
  LIFENUMBER: '',
  RIDERNUMBER: '',
  PLANSHORTDESC: '',
  PLANCODE: '',
  PLANSUBDESC: '',
  PLANCODEDESC: '',
};

const initialDispatchAddress = {
  ADDRESS5: '',
  ADDRESS4: '',
  ADDRESSTYPE: '',
  ZIPCODE: '',
  ALTCLIENTCODE: '',
  COUNTRYCODE: '',
  ALTCLIENTIND: '',
  SMSFLAG: '',
  ADDRESS1: '',
  CLIENTCODE: '',
  ADDRESS3: '',
  MAILINGSTATUS: '',
  ADDRESS2: '',
};

const initialClientInfo = {
  NATIONALITY: '',
  IDENTITYTYPE: '',
  ADDRESSTYPE: '',
  MAIDENLASTNAME: '',
  IDENTITYNO: '',
  USTINNO: '',
  FIRSTNAME: '',
  BIRTHDATE: '',
  EMAIL: '',
  STATUS: '',
  EXTENSIONNAME: '',
  MIDDLENAME: '',
  LASTNAME: '',
  MARITALSTATUS: '',
  ADDRESSSMSFLAG: '',
  ALTCLIENTIND: '',
  GENDER: '',
  SMSFLAG: '',
  ADDRESS1: '',
  GSISnumber: '',
  CLIENTCODE: '',
  ADDRESS3: '',
  ADDRESS2: '',
  ADDRESS5: '',
  ADDRESS4: '',
  ZIPCODE: '',
  MIB: '',
  CLIENTTYPE: '',
  MOBILENO: '',
  BIRTHPLACE: '',
  FATCA: '',
  MAIDENFIRSTNAME: '',
  FATCAEFFDATE: 0,
  ALTCLIENTCODE: '',
  COUNTRYCODE: '',
  OCCUPATIONCODE: '',
  TITLE: '',
  SECURITYNO: '',
  PHONE2: '',
  PHONE1: '',
  FAX: '',
  SSSNUMBER: '',
  TINNUMBER: '',
  MAILINGSTATUS: '',
  AMLA: '',
};

const initialBankInfo = {
  FROMYEAR: 0,
  EXPIRYYEAR: 0,
  MANDATEREFNO: '',
  ACCOUNTTYPE: '',
  ACCOUNTNUMBER: '',
  BANKNAME: '',
  ACCOUNTNAME: '',
  EXPIRYMONTH: 0,
  POLICYNUMBER: '',
  BANKKEY: '',
  PAYERNUMBER: '',
  CARDTYPE: 0,
  FROMMONTH: 0,
};
export const maintenanceModel = {
  maintenance: [
    {
      ID: 0,
      isVisible: 0,
    },
  ],
};
export const initial = {
  'insurance-type': insuranceTypeInitial,
  'policy-list': [
    {
      POLICYNUMBER: '',
      PLANCATEGORY: '',
      ROLECODE: '',
      CLIENTCODE: '',
    },
  ],
  'policy-detail': {
    POLICYINFO: [initialPolicyInfo],
    POLICYFUND: [initialPolicyFund],
    AGENTINFO: [initialAgentInfo],
    PRODUCTINFO: [initialProductInfo],
    DISPATCHADDRESS: [initialDispatchAddress],
    CLIENTINFO: [initialClientInfo],
    BANKINFO: [initialBankInfo],
  },
  'policy-rpqscore': [
    {
      RPQSCORE: 0,
      POLICYNUMBER: '',
    },
  ],
  'policydocument-list': [
    {
      DOCUMENTID: '',
      TRANSACTION_DATE: '',
      POLICYNUMBER: '',
      DOCUMENTCD: '',
      DOCTYPE: '',
      DOCUMENT_DESC: '',
    },
  ],
  'policy-roles': [
    {
      RELATION: '',
      CLAIMPOLICYHISTORY: '',
      COMPANYCODE: '',
      EFFECTIVEFROMDATE: '',
      EFFECTIVETODATE: null,
      DECISIONCD: null,
      FINANCIALSAR: null,
      ROLECODE: '',
      BENIFIRYTYPE: '',
      AGEATISSUE: null,
      REVOCABLEFL: '',
      ADVERSEPOLICYHISTORY: '',
      ASSIGNMENTTYPECD: null,
      IRREVOCABLEFL: null,
      POLICYNUMBER: '',
      RELATIONSHIPCODE: '',
      CLIENTCODE: '',
      ALLOCATIONPERCENTAGE: 0,
      CLIENTREFSEQ: '',
    },
  ],
  'policy-paymentinfo': [
    {
      TOTALINSTALLMENTPREMIUM: 0,
      INSTALLMENTPREMIUM: 0,
      BILLTODATE: '',
      PAYMENTMODE: '',
      FIRSTNAME: '',
      BIRTHDATE: '',
      POLICYDATE: '',
      EMAIL: '',
      PAYMENTMETHOD: '',
      LIFESUMINSURED: 0,
      EXTENSIONNAME: '',
      MIDDLENAME: '',
      PREMIUMSTATUSCODE: '',
      LASTNAME: '',
      BILLFREQUENCY: '',
      PLANCODE: '',
      CLIENTCODE: '',
      STATUSCODE: '',
      BILLINGCHANNEL: '',
      SINGLEPREMIUM: '',
      CURRENCYCODE: '',
      PAIDTODATE: '',
      POLICYNUMBER: '',
      TITLE: '',
      PLANCODEDESC: '',
    },
  ],
  BO_available: false,
};
