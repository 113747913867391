import styled from 'styled-components';

const StyledErrorBox = styled.div`
  z-index: 100;
  position: relative;
  padding: 16px;
  background-color: ${props => props.theme.bgError};
  border-radius: 4px;
  font-size: 15px;
  color: #b30909;
  min-width: 376px;
  max-width: 500px;
  width: 100%;
  margin-top: 80px;
  @media only screen and (max-width: 768px) {
    top: 82px;
    height: auto;
    min-height: 55px;
    display: flex;
    justify-content: center;
    left: 0;
    border-radius: 0;
    font-size: 15px;
    padding: 0 8px;
    align-items: center;
    margin-top: 0;
  }
  .la {
    vertical-align: middle;
    font-weight: bold;
  }
  .la-exclamation-circle {
    margin-right: 6px;
    font-size: 18px;
    word-spacing: 54px;
    display: contents;
    letter-spacing: 15px;
  }
  .la-times {
    color: #636566;
    @media only screen and (max-width: 768px) {
      margin-left: 5%;
    }
  }
`;

export default StyledErrorBox;
