import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const GridFullHeight: any = styled(Grid)`
  height: auto !important;
  height: 100%;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    min-height: 50vh !important;
  }
`;

export default GridFullHeight;
