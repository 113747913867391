import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
