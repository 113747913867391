export const GET_PAYMENT_HISTORY_REQUEST: string =
  'GET_PAYMENT_HISTORY_REQUEST';
export const GET_PAYMENT_HISTORY_SUCCESS: string =
  'GET_PAYMENT_HISTORY_SUCCESS';

export const GET_PAYMENT_DUE_REQUEST: string = 'GET_PAYMENT_DUE_REQUEST';
export const GET_PAYMENT_DUE_SUCCESS: string = 'GET_PAYMENT_DUE_SUCCESS';

export const SET_PAYMENT_LOADING: string = 'SET_PAYMENT_LOADING';
export const SET_PAYMENT_NOT_LOADING: string = 'SET_PAYMENT_NOT_LOADING';

export const GET_INITIALPAYMENT_DUE_REQUEST: string =
  'GET_INITIALPAYMENT_DUE_REQUEST';
export const GET_INITIALPAYMENT_DUE_SUCCESS: string =
  'GET_INITIALPAYMENT_DUE_SUCCESS';

export const SET_INITIALPAYMENT_LOADING: string = 'SET_INITIALPAYMENT_LOADING';
export const SET_INITIALPAYMENT_NOT_LOADING: string =
  'SET_INITIALPAYMENT_NOT_LOADING';

export const GET_DRAGON_TOKEN_ID: string = 'GET_DRAGON_TOKEN_ID';
export const SET_DRAGON_TOKEN_ID_LOADING: string =
  'SET_DRAGON_TOKEN_ID_LOADING';
export const SET_DRAGON_TOKEN_ID_NOT_LOADING: string =
  'SET_DRAGON_TOKEN_ID_NOT_LOADING';

export const GET_DRAGON_BILLING: string = 'GET_DRAGON_BILLING';
export const SET_DRAGON_BILLING: string = 'SET_DRAGON_BILLING';

export const SET_PAYMENT_RECEIPTION: string = 'SET_PAYMENT_RECEIPTION';

export const SET_IS_PROCEED: string = 'SET_IS_PROCEED';
export const SET_IS_NOT_PROCEED: string = 'SET_IS_NOT_PROCEED';

export const SET_TRANSACTION: string = 'SET_TRANSACTION';
