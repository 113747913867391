import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      boxShadow: '4px 4px 10px rgba(216, 237, 239, 0.3)',
    },
    header: {
      color: '#CCCCCC',
      backgroundColor: '#222222',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 100px',
    },
    nav_header: {
      height: '85px',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 100px 0 115px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px 0 20px',
        position: 'relative',
      },
    },
    left_session: {
      float: 'left',
    },
    right_session: {
      float: 'right',
    },
    session: {
      color: '#CCCCCC',
      margin: '0 15px',
    },
    logo: {
      width: '130px',
      height: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '60px',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
      },
    },
    header_optional: {
      margin: '0 15px',
      color: '#F68B1F',
      fontWeight: 'bold',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paper: {
      width: '100%',
      backgroundColor: '#222222',
    },
    menuButton: {
      color: 'red',
    },
    menuHeader: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
    },
    linkHeader: {
      paddingLeft: '15px',
      paddingBottom: '5px',
    },
    listMenu: {
      padding: '0px',
      marginTop: '-10px',
    },
    menu_option: {
      paddingBottom: '14px',
      color: '#F68B1F',
      fontSize: '16px',
      display: 'block',
      width: '100%',
    },
    header_option: {
      fontWeight: 'bold',
      paddingRight: '10px',
      color: '#CCCCCC',
      fontSize: '16px',
    },
    rootListItem: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    menuIcon: {
      color: '#CCCCCC',
    },
    closeIconNL: {
      backgroundColor: '#e87722',
    },
    whiteBG: { backgroundColor: '#fff' },
    listItem: {
      padding: '8px 8px 8px 16px',
      display: 'flex',
      alignItems: 'flex-start',
      textDecoration: 'none',
      color: '#183028',
      fontSize: '1rem',
      fontFamily: 'FWDCircularWeb, sans-serif',
      fontWeight: 300,
      lineHeight: '1.5rem',
      letterSpacing: '0.00938em',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
    },
    listItemHovered: {
      padding: '8px 8px 8px 16px',
      display: 'flex',
      alignItems: 'flex-start',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#e87722',
      fontSize: '1rem',
      fontFamily: 'FWDCircularWeb, sans-serif',
      fontWeight: 300,
      lineHeight: '1.5rem',
      letterSpacing: '0.00938em',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
    },
    marginLeft: {
      marginLeft: '20px',
    },
  })
);

export default useStyles;
