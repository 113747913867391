export const SET_OPEN_TOP_ALERT: string = 'SET_OPEN_TOP_ALERT';
export const SET_CLOSE_TOP_ALERT: string = 'SET_CLOSE_TOP_ALERT';
export const SET_IS_CALL_INSURANCE: string = 'SET_IS_CALL_INSURANCE';
export const SET_IS_NOT_CALL_INSURANCE: string = 'SET_IS_NOT_CALL_INSURANCE';
export const SET_CONTENT_TOP_ALERT: string = 'SET_CONTENT_TOP_ALERT';
export const SET_IS_LOADING: string = 'SET_IS_LOADING';
export const SET_IS_NOT_LOADING: string = 'SET_IS_NOT_LOADING';
export const SET_DISABLE_BUTTON_LOGIN: string = 'SET_DISABLE_BUTTON_LOGIN';
export const SET_NOT_DISABLE_BUTTON_LOGIN: string =
  'SET_NOT_DISABLE_BUTTON_LOGIN';
