import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_INITIALPAYMENT_DUE_REQUEST,
  GET_INITIALPAYMENT_DUE_SUCCESS,
  SET_INITIALPAYMENT_LOADING,
} from 'redux/reducers/payment/actionTypes';
import {
  SET_OPEN_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
} from 'redux/reducers/global/actionTypes';
import { initialPaymentDueApi } from 'services/payment/paymentApi';

function* requestInitialPaymentDue(action) {
  console.log('ACCESSSSSSSS INITAL PAYMENT REQUEST SAGA!');
  const location =
    action && action.params && action.params.location
      ? action.params.location
      : null;
  let params = action.params;
  delete params.location;
  action.params = params;

  if (location === null) {
    yield put({ type: SET_INITIALPAYMENT_LOADING });
  }

  const result = yield call(initialPaymentDueApi, action.params);
  console.log(
    'ACCESSSSSSSS INITAL PAYMENT REQUEST SAGA!',
    result,
    action.params
  );
  if (
    result &&
    result.response &&
    result.error &&
    result.response.status &&
    result.response.statusText
  ) {
    if (location !== null) {
      const topAlertContent = {
        iconType: 'la la-exclamation-circle',
        message: `Sorry, ${result.response.status} ${result.response.statusText}`,
        iconClose: 'la la-times',
      };
      yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
      yield put({ type: SET_OPEN_TOP_ALERT });
      return;
    } else {
      window.location.replace(`http://10.192.20.167/`);
      const topAlertContent = {
        iconType: 'la la-exclamation-circle',
        message: `Sorry, ${result.response.status} ${result.response.statusText}`,
        iconClose: 'la la-times',
      };
      yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
      yield put({ type: SET_OPEN_TOP_ALERT });
      return;
    }
  } else if (
    result &&
    result.response &&
    result.error &&
    !result.response.status &&
    !result.response.statusText
  ) {
    if (location !== null) {
      const topAlertContent = {
        iconType: 'la la-exclamation-circle',
        message: `Sorry, ${result.response}`,
        iconClose: 'la la-times',
      };
      yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
      yield put({ type: SET_OPEN_TOP_ALERT });
      return;
    } else {
      window.location.replace(`http://10.192.20.167/`);
      const topAlertContent = {
        iconType: 'la la-exclamation-circle',
        message: `Sorry, ${result.response}`,
        iconClose: 'la la-times',
      };
      yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
      yield put({ type: SET_OPEN_TOP_ALERT });
      return;
    }
  }
  if (!!result) {
    yield put({ type: GET_INITIALPAYMENT_DUE_SUCCESS, result });
  }
}
function* getInitialPaymentDue() {
  yield takeLatest(GET_INITIALPAYMENT_DUE_REQUEST, requestInitialPaymentDue);
}

export default getInitialPaymentDue;
