export const GET_POLICY_DETAIL_REQUEST: string = 'GET_POLICY_DETAIL_REQUEST';
export const GET_POLICY_DETAIL_SUCCESS: string = 'GET_POLICY_DETAIL_SUCCESS';

export const GET_PAYMENT_DETAIL_REQUEST: string = 'GET_PAYMENT_DETAIL_REQUEST';
export const GET_PAYMENT_DETAIL_SUCCESS: string = 'GET_PAYMENT_DETAIL_SUCCESS';

export const SET_INSURANCE_TYPE: string = 'SET_INSURANCE_TYPE';
export const GET_INSURANCE_TYPE: string = 'GET_INSURANCE_TYPE';

export const GET_BO_DETAIL_REQUEST: string = 'GET_BO_DETAIL_REQUEST';
export const GET_BO_DETAIL_SUCCESS: string = 'GET_BO_DETAIL_SUCCESS';

export const GET_MAINTENANCE_DETAIL_REQUEST: string =
  'GET_MAINTENANCE_DETAIL_REQUEST';
export const GET_MAINTENANCE_DETAIL_SUCCESS: string =
  'GET_MAINTENANCE_DETAIL_SUCCESS';
