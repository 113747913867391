import { takeLatest, put, call, delay, race } from 'redux-saga/effects';

import {
  GET_INSURANCE_TYPE,
  GET_PAYMENT_DETAIL_REQUEST,
  GET_PAYMENT_DETAIL_SUCCESS,
  GET_MAINTENANCE_DETAIL_REQUEST,
  GET_MAINTENANCE_DETAIL_SUCCESS,
} from 'redux/reducers/policy/actionTypes';
import {
  SET_OPEN_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
  SET_IS_NOT_LOADING,
  SET_DISABLE_BUTTON_LOGIN,
} from 'redux/reducers/global/actionTypes';

import {
  GET_PAYMENT_DUE_REQUEST,
  GET_INITIALPAYMENT_DUE_REQUEST,
} from 'redux/reducers/payment/actionTypes';

import {
  paymentDetailApi,
  businessDateApi,
  maintenanceApi,
} from 'services/policy/policyApi';

function* checkMaintenanceIsUp() {
  const maintenance = yield call(maintenanceApi);
  yield put({ type: GET_MAINTENANCE_DETAIL_SUCCESS, maintenance });
  return;
}

function* requestPaymentPolicyDetail(action) {
  const policy = yield call(paymentDetailApi, action.payload.data);

  const isEmptyPolicy = !(
    !!policy && !(policy['policy-paymentinfo'][0] === undefined)
  );
  const premiumStatusCodeArray = ['PS', 'UW', 'NP', 'NC'];
  const isInitialPayment = isEmptyPolicy
    ? false
    : premiumStatusCodeArray.includes(
        policy['policy-paymentinfo'][0].PREMIUMSTATUSCODE
      );
  const params = { FILLER: 'A' };
  const { bo, timeout } = yield race({
    bo: call(businessDateApi, params),
    timeout: delay(5 * 1000),
  });
  console.log('bo', bo);

  if (policy && policy.response && policy.error) {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: `Sorry, ${policy.response.status} ${policy.response.statusText}`,
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    yield put({ type: SET_DISABLE_BUTTON_LOGIN });
    yield put({ type: SET_IS_NOT_LOADING });
    return;
  }

  if (timeout || bo.error) {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: `Sorry, this facility is currently not available at the moment. Kindly retry in an hour`,
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    yield put({ type: SET_DISABLE_BUTTON_LOGIN });
    yield put({ type: SET_IS_NOT_LOADING });
    return;
  }

  if (!!policy && !(policy['policy-paymentinfo'][0] === undefined)) {
    yield put({
      type: GET_INSURANCE_TYPE,
      params: policy['policy-paymentinfo'][0].PLANCODE,
    });
    yield put({ type: GET_PAYMENT_DETAIL_SUCCESS, policy });
    if (isInitialPayment) {
      yield put({
        type: GET_INITIALPAYMENT_DUE_REQUEST,
        params: {
          POLICYNUMBER: policy['policy-paymentinfo'][0].POLICYNUMBER,
          APPLICATIONNUMBER:
            '01' + policy['policy-paymentinfo'][0].POLICYNUMBER,
          location: '/',
        },
      });
    } else {
      yield put({
        type: GET_PAYMENT_DUE_REQUEST,
        params: {
          POLICYNUM: policy['policy-paymentinfo'][0].POLICYNUMBER,
          location: '/',
        },
      });
    }
  } else {
    const topAlertContent = {
      iconType: 'la la-exclamation-circle',
      message: 'Policy number and Date of Birth do not match.',
      iconClose: 'la la-times',
    };
    yield put({ type: SET_CONTENT_TOP_ALERT, topAlertContent });
    yield put({ type: SET_OPEN_TOP_ALERT });
    yield put({ type: SET_DISABLE_BUTTON_LOGIN });
  }
  yield put({ type: SET_IS_NOT_LOADING });
}

function* getPolicy() {
  yield takeLatest(GET_PAYMENT_DETAIL_REQUEST, requestPaymentPolicyDetail);
  yield takeLatest(GET_MAINTENANCE_DETAIL_REQUEST, checkMaintenanceIsUp);
}

export default getPolicy;
