import React, { lazy, Suspense } from 'react';
import { GridFullHeight } from 'components';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from './routes';
import { CircularProgress } from '@material-ui/core';
const AuthPayment = lazy(() => import('modules/AuthPayment'));
const Payment = lazy(() => import('modules/Payment'));
const PaymentCompleted = lazy(() =>
  import('modules/Payment/containers/PaymentCompleted')
);
const PaymentDenied = lazy(() => import('modules/Payment/containers/Expired'));
const Maintenance = lazy(() => import('components/MaintenancePage'));

const Routes = props => (
  <Suspense
    fallback={
      <GridFullHeight
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <CircularProgress style={{ color: '#E9781C' }} />
      </GridFullHeight>
    }
  >
    <Switch>
      <Route
        exact
        path={routes.paymentCompleted}
        component={PaymentCompleted}
      />
      <Route exact path={routes.paymentDenied} component={PaymentDenied} />
      <Route exact path={routes.payment} component={Payment} />
      <Route exact path={routes.authPayment} component={AuthPayment} />
      <Route exact path={routes.maintenance} component={Maintenance} />
    </Switch>
  </Suspense>
);

export default connect(null)(Routes);
