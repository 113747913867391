import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { maintenanceReducer, policyReducer } from './policy';
import { paymentReducer } from './payment';
import createPersistReducer from 'redux/reducers/persist';
import { policyType, policyPaymentInfo, maintenanceState } from 'models/policy';
import { globalState, globalReducer } from './global';
import { paymentType } from './payment';

const createRootReducer = (history: History) => {
  const reducers = combineReducers({
    policy: policyReducer,
    paymentinfo: policyReducer,
    payment: paymentReducer,
    maintenance: maintenanceReducer,
    router: connectRouter(history),
    global: globalReducer,
  });
  return createPersistReducer(reducers);
};

export interface reducerType {
  policy: policyType;
  paymentinfo: policyPaymentInfo;
  global: globalState;
  payment: paymentType;
  maintenance: maintenanceState;
  router: {
    location: {
      pathname: string;
      search: string;
      hash: string;
    };
    action: string;
  };
}

export default createRootReducer;
