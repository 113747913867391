import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import useStyles from './styles';

interface ButtonsProps {
  disabled?: boolean;
  fullWidth?: boolean;
  children: string;
}

const Buttons = memo((props: ButtonsProps) => {
  const classes = useStyles();
  const { disabled, fullWidth, children } = props;
  return (
    <Button
      className={disabled ? classes.btnDisabled : ''}
      disabled={disabled}
      disableFocusRipple={true}
      disableRipple={true}
      classes={{ root: classes.root }}
      type="submit"
      fullWidth={props.fullWidth}
    >
      {children}
    </Button>
  );
});
export default Buttons;
