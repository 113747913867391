import { all } from 'redux-saga/effects';
import getPaymentDue from './getPaymentDue';
import getInitialPaymentDue from './getInitialPaymentDue';
import getDragonTokenId from './getDragonTokenId';
import getDragonBill from './getDragonBill';

export const paymentSagas = function* root() {
  yield all([
    getPaymentDue(),
    getInitialPaymentDue(),
    getDragonTokenId(),
    getDragonBill(),
  ]);
};
