const lightGray = '#f8f8f8';
const Gray = '#E5E5E5';
const hardGary = '#A4A4A4';
const Red = '#e02020';
const RedPink = ' #db4437';
const lightRed = '#FEF3F3';
const OrangeBackground = '#E77821';
const btnBlueInv = '#E2F5F0';
const textBtnBlueInv = '#0097A9';
const isStatusActiveInvestment = '#0097A9;';
const isStatusInactiveInvestment = '#8C9894;';
const textPrice = '#183028';
//main color
const mainColor = '#f68b1f';

// main text
const mainText = '#222222';
const mainTitle = '#FFFFFF';

const theme = {
  //backGround
  bg: '#FFFFFF',
  bgContainer: 'white',
  bgButton: OrangeBackground,
  bgError: lightRed,
  bgHover: lightGray,

  btnBlueInv: btnBlueInv,
  textBtnBlueInv: textBtnBlueInv,
  isStatusActiveInvestment: isStatusActiveInvestment,
  isStatusInactiveInvestment: isStatusInactiveInvestment,
  textPrice: textPrice,

  // button
  btnDisable: Gray,

  //background
  MainBg: mainColor,

  //text
  text: mainText,
  title: mainTitle,
  subTitle: mainColor,

  textExplanation: Gray,
  textLabel: hardGary,
  textError: Red,
  icon: Gray,
  textBreadcrumbs: hardGary,
  warnIcon: RedPink,
  //other
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default theme;
