interface routes {
  authPayment: string;
  payment: string;
  paymentCompleted: string;
  paymentDenied: string;
  maintenance: string;
}

export const routes: routes = {
  authPayment: '/',
  payment: '/payment',
  paymentCompleted: '/payment/completed',
  paymentDenied: '/expired',
  maintenance: '/maintenance',
};
