import styled from 'styled-components';

const ErrorMessage = styled.div`
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    width: inherit;
    text-align: center;
  }
`;

export default ErrorMessage;
