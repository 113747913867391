import {
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_DUE_SUCCESS,
  GET_INITIALPAYMENT_DUE_SUCCESS,
  SET_PAYMENT_LOADING,
  SET_PAYMENT_NOT_LOADING,
  SET_DRAGON_TOKEN_ID_LOADING,
  SET_DRAGON_TOKEN_ID_NOT_LOADING,
  SET_DRAGON_BILLING,
  SET_PAYMENT_RECEIPTION,
  SET_IS_PROCEED,
  SET_IS_NOT_PROCEED,
  SET_TRANSACTION,
} from './actionTypes';
import {
  paymentDue,
  initialPaymentDue,
  paymenthistoryList,
  dragonBill,
  dragonBillInitial,
} from 'models/payment';
import { produce } from 'immer';

export interface paymentType {
  'paymenthistory-list': paymenthistoryList[];
  'payment-due': paymentDue[];
  'initialpayment-due': initialPaymentDue[];
  isLoading: boolean;
  isGetTokenDragonLoading: boolean;
  dragonBill: dragonBill;
  receiptionId: string;
  isProceed: boolean;
  transaction: {
    TokenId: string;
    TransactionId: string;
  };
}

export const initial: paymentType = {
  'paymenthistory-list': [],
  'payment-due': [],
  'initialpayment-due': [],
  isLoading: false,
  isGetTokenDragonLoading: false,
  dragonBill: dragonBillInitial,
  receiptionId: '',
  isProceed: false,
  transaction: {
    TokenId: '',
    TransactionId: '',
  },
};

export const paymentReducer = (state = initial, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PAYMENT_HISTORY_SUCCESS:
        draft['paymenthistory-list'] = action.payload['paymenthistory-list'];
        break;
      case GET_PAYMENT_DUE_SUCCESS:
        draft['payment-due'] = action.result['payment-due'];
        break;
      case GET_INITIALPAYMENT_DUE_SUCCESS:
        draft['initialpayment-due'] = action.result['initialpayment-due'];
        break;
      case SET_PAYMENT_LOADING:
        draft['isLoading'] = true;
        break;
      case SET_PAYMENT_NOT_LOADING:
        draft['isLoading'] = false;
        break;
      case SET_DRAGON_TOKEN_ID_LOADING:
        draft['isGetTokenDragonLoading'] = true;
        break;
      case SET_DRAGON_TOKEN_ID_NOT_LOADING:
        draft['isGetTokenDragonLoading'] = false;
        break;
      case SET_DRAGON_BILLING:
        draft.dragonBill = { ...action.result };
        break;
      case SET_PAYMENT_RECEIPTION:
        draft['receiptionId'] = action;
        break;
      case SET_IS_PROCEED:
        draft['isProceed'] = true;
        break;
      case SET_IS_NOT_PROCEED:
        draft['isProceed'] = false;
        break;
      case SET_TRANSACTION:
        draft['transaction'] = { ...action.result };
        break;
      default:
        return draft;
    }
  });

export default { paymentReducer };
