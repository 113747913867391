export interface globalType {
  isOpenTopAlert: boolean;
}

export interface topAlertContent {
  iconType: string;
  message: any;
  iconClose: string;
}

export const initialTopAlertontent = {
  iconType: 'la la-exclamation-circle',
  message: 'Policy number and Date of Birth do not match',
  iconClose: 'la la-times',
};
