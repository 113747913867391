import {
  GET_POLICY_DETAIL_SUCCESS,
  SET_INSURANCE_TYPE,
  GET_PAYMENT_DETAIL_SUCCESS,
  GET_BO_DETAIL_SUCCESS,
  GET_MAINTENANCE_DETAIL_SUCCESS,
} from './actionTypes';
import { produce } from 'immer';
import { initial, maintenanceModel } from 'models/policy';

export const policyReducer = (state = initial, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_POLICY_DETAIL_SUCCESS:
        draft['policy-detail'] = action.policy['policy-detail'];
        break;
      case GET_PAYMENT_DETAIL_SUCCESS:
        draft['policy-paymentinfo'] = action.policy['policy-paymentinfo'];
        break;
      case GET_BO_DETAIL_SUCCESS:
        draft['business-date2'] = action.policy['business-date2'];
        break;
      case SET_INSURANCE_TYPE:
        draft['insurance-type'] = { ...action.result };
        break;
      default:
        return draft;
    }
  });

export const maintenanceReducer = (state = maintenanceModel, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_MAINTENANCE_DETAIL_SUCCESS:
        draft['maintenance'] = action.maintenance;
        break;
      default:
        return draft;
    }
  });

export default { policyReducer, maintenanceReducer };
