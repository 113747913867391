import axios from 'axios';

export const FwdAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_FWD_API,
});

export const setToken = (token: string) => {
  FwdAPIInstance.defaults.headers.common['Authorization'] = token;
};

setToken(`Basic ${process.env.REACT_APP_API_TOKEN}`);

FwdAPIInstance.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    throw error;
  }
);
