import styled from 'styled-components';

const PaymentTable: any = styled.div`
  width: 50%;
  border: 1px solid #e5e5e5;
  margin: 50px 0 0 0;
  border-radius: 4px;
  box-sizing: border-box;
`;

export default PaymentTable;
