import produce from 'immer';
import { topAlertContent, initialTopAlertontent } from 'models/global';
import {
  SET_OPEN_TOP_ALERT,
  SET_CLOSE_TOP_ALERT,
  SET_CONTENT_TOP_ALERT,
  SET_IS_LOADING,
  SET_IS_NOT_LOADING,
  SET_DISABLE_BUTTON_LOGIN,
  SET_NOT_DISABLE_BUTTON_LOGIN,
  SET_IS_CALL_INSURANCE,
  SET_IS_NOT_CALL_INSURANCE,
} from './actionTypes';

export interface globalState {
  isOpenTopAlert: boolean;
  isLoading: boolean;
  topAlertContent: topAlertContent;
  isDisableButtonLogin: boolean;
  isCallInsurance: boolean;
}

const initial = {
  isOpenTopAlert: false,
  isLoading: false,
  topAlertContent: initialTopAlertontent,
  isDisableButtonLogin: false,
  isCallInsurance: false,
};

export const globalReducer = (state = initial, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_OPEN_TOP_ALERT: {
        draft['isOpenTopAlert'] = true;
        break;
      }
      case SET_DISABLE_BUTTON_LOGIN: {
        draft['isDisableButtonLogin'] = true;
        break;
      }
      case SET_NOT_DISABLE_BUTTON_LOGIN: {
        draft['isDisableButtonLogin'] = false;
        break;
      }
      case SET_CLOSE_TOP_ALERT: {
        draft['isOpenTopAlert'] = false;
        break;
      }
      case SET_CONTENT_TOP_ALERT: {
        draft['topAlertContent'] = action['topAlertContent'];
        break;
      }
      case SET_IS_LOADING: {
        draft['isLoading'] = true;
        break;
      }
      case SET_IS_NOT_LOADING: {
        draft['isLoading'] = false;
        break;
      }
      case SET_IS_CALL_INSURANCE: {
        draft['isCallInsurance'] = true;
        break;
      }
      case SET_IS_NOT_CALL_INSURANCE: {
        draft['isCallInsurance'] = false;
        break;
      }
    }
  });
export default {
  globalReducer,
};
